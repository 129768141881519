import { CSSReset, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme as leafTheme } from '@getjust/leaf';
import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';

import type { DehydratedState } from '@tanstack/react-query';
import type { AppProps } from 'next/app';

import { ErrorBoundary } from '$components/ErrorBoundary';
import '$src/styles/globals.scss';
import 'react-phone-number-input/style.css';
import '$src/styles/formLoader.scss';
import '@getjust/leaf/dist/style.css';
import { InitWrapper } from '$src/components/InitWrapper';
import { Layout } from '$src/components/Layout';
import { AuthProvider } from '$src/hooks/auth';
import { reportQueryError } from '$src/http';
import { initAmplitude } from '$src/utils/amplitude';

type Props = {
  dehydratedState: DehydratedState;
  cartId: string;
  sellerId: string;
};

const theme = extendTheme({
  ...leafTheme,
  styles: {
    global: () => ({
      body: {
        bg: '#fffff',
      },
    }),
  },
});

/**
 * FAQ:
 * - Why no jotai (state) provider in the react tree ?
 * cf: https://github.com/pmndrs/jotai/discussions/384
 */

initAmplitude();

function App({ Component, pageProps }: AppProps<Props>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error: any) => {
            reportQueryError(error);
          },
        }),
        queryCache: new QueryCache({
          onError: (error: any) => {
            reportQueryError(error);
          },
        }),
        defaultOptions: {
          queries: {
            staleTime: 5 * (60 * 1000), // 10 mins
            gcTime: 10 * (60 * 1000), // 10 mins
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            throwOnError: false,
          },
        },
      }),
  );

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
      return;
    }

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string, {
      api_host: 'https://app.posthog.com',
      loaded: (params) => {
        if (
          process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ||
          !process.env.NEXT_PUBLIC_ENABLE_ANALYTICS
        ) {
          params.opt_out_capturing();
        }
      },
      autocapture: true,
      save_referrer: true,
    });
  }, []);

  return (
    <>
      <Head>
        <title>JUST Checkout</title>
        <meta name="description" content="One click checkout" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ChakraProvider resetCSS theme={theme}>
        <CSSReset />
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <InitWrapper>
                <AuthProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </AuthProvider>
              </InitWrapper>
            </HydrationBoundary>
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(App);
